import axios from "axios";

const host = process.env.HOST;
export const USE_PROD = process.env.TEST !== "true";

export let BASE_URL = USE_PROD
  ? "https://" + host + "/api/v1/"
  : "http://" + host + ":3002/api/v1/";

export const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
  withCredentials: true,
  timeout: process.env.NODE_ENV === "production" ? 10000 : 10000000,
});

export const puzzleApi = axios.create({
  baseURL: "https://www.chesshotel.com/api/v1/",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
  withCredentials: true,
  timeout: process.env.NODE_ENV === "production" ? 10000 : 10000000,
});
