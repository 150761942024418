import React from "react";
import { UNLIMITED_TIER } from "@chesshotel/constants";
import UserAvatar from "../modules/UserAvatar";
import { FaUser } from "@react-icons/all-files/fa/FaUser";
import { FaCog } from "@react-icons/all-files/fa/FaCog";
import { FaSignOutAlt } from "@react-icons/all-files/fa/FaSignOutAlt";
import { FaInfinity } from "@react-icons/all-files/fa/FaInfinity";
import { FaTrophy } from "@react-icons/all-files/fa/FaTrophy";
import { FaUsers } from "@react-icons/all-files/fa/FaUsers";
import { signOut } from "../ducks/signIn";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import Link from "next/link";
import { IoClose } from "@react-icons/all-files/io5/IoClose";
import { useUser } from "../ducks/user";
import { useMembership } from "../ducks/membership";

function NButton({ href, exact = false, children, ...props }) {
  const { pathname } = useRouter();
  const isActive = exact ? pathname === href : pathname.startsWith(href);

  return (
    <Link href={href}>
      <button
        className={`btn btn-goast btn-base inline-flex items-center w-full
        ${isActive ? "text-red-500" : ""}`}
        {...props}
      >
        {children}
      </button>
    </Link>
  );
}

export default function MobileMenuDrawer({ onClose, open }) {
  const { t } = useTranslation("common");
  // const { t: lobbyT } = useTranslation("lobby");

  const { isGuest, username } = useUser();
  const { tier, leagueGames, analyzes } = useMembership();

  return (
    <>
      <div
        className={`absolute md:hidden top-0 left-0 w-full h-full z-30 bg-black opacity-0 transition-opacity
        ${open ? "block opacity-40" : "hidden"}
        `}
        onClick={onClose}
      ></div>
      <div
        className={`fixed flex md:hidden flex-col top-0 left-0 w-3/4 h-full p-8 pt-6 px-4 bg-white z-40 transition-transform ease-in 
    ${open ? "translate-x-0" : "-translate-x-full"}
    `}
      >
        {/* <div className="relative z-40 w-3/4 h-full p-8 px-4 pt-6 bg-white"> */}
        <button
          onClick={onClose}
          className="absolute right-2 top-2 btn btn-icon btn-icon-md btn-goast"
        >
          <div className="text-2xl">
            <IoClose />
          </div>
        </button>

        <div className="flex flex-col w-auto px-4">
          <UserAvatar className="w-11 h-11" />
          <div className="mt-1 font-bold text-md">
            {isGuest && username ? username.substr(2) : username}
          </div>
          {!isGuest && (
            <div className="flex items-center mt-1 space-x-4">
              <div className="flex items-center space-x-1">
                <div className="text-xs font-bold">
                  {tier === UNLIMITED_TIER ? <FaInfinity /> : leagueGames}
                </div>
                <div className="text-xs">{t("League games")}</div>
              </div>
              <div className="flex items-center space-x-1">
                <div className="text-xs font-bold">
                  {tier === UNLIMITED_TIER ? <FaInfinity /> : analyzes}
                </div>
                <div className="text-xs">{t("Analyzes")}</div>
              </div>
            </div>
          )}
        </div>
        <div className="w-auto h-px mx-4 mt-3 bg-gray-100" />
        <div className="flex flex-col items-start pt-2">
          {isGuest && (
            <NButton exact href="/" onClick={onClose}>
              {t("Home")}
            </NButton>
          )}
          <NButton href="/lobby" onClick={onClose}>
            {t("Play")}
          </NButton>
          <NButton href="/players" onClick={onClose}>
            {t("Players")}
          </NButton>
          <NButton href="/puzzle" onClick={onClose}>
            {t("Puzzles")}
          </NButton>
        </div>

        <div className="w-auto h-px mx-4 my-2 bg-gray-100" />

        {!isGuest ? (
          <div className="flex flex-col items-start">
            <NButton href="/profile" onClick={onClose}>
              <FaUser />
              <div className="ml-2">{t("Profile")}</div>
            </NButton>
            <NButton href="/settings/0" onClick={onClose}>
              <FaCog />
              <div className="ml-2">{t("Settings")}</div>
            </NButton>
            <NButton href="/friends" onClick={onClose}>
              <FaUsers />
              <div className="ml-2">{t("Friends")}</div>
            </NButton>
            <NButton href="/leagues" onClick={onClose}>
              <FaTrophy />
              <div className="ml-2">{t("Leagues")}</div>
            </NButton>
            <button
              className="inline-flex items-center w-full btn btn-goast btn-base"
              onClick={() => {
                onClose();
                signOut();
              }}
            >
              <FaSignOutAlt />
              <div className="ml-2">{t("Sign out")}</div>
            </button>
          </div>
        ) : (
          <div className="flex flex-col items-start mt-3 space-y-1">
            <NButton href="/signIn" onClick={onClose}>
              {t("Log in")}
            </NButton>
            <NButton href="/register" onClick={onClose}>
              {t("Sign up")}
            </NButton>
          </div>
        )}
      </div>
      {/* </div> */}
    </>
  );
}
