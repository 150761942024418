const russianTimezones = [
  "Asia/Anadyr",
  "Asia/Barnaul",
  "Asia/Chita",
  "Asia/Irkutsk",
  "Asia/Kamchatka",
  "Asia/Khandyga",
  "Asia/Krasnoyarsk",
  "Asia/Magadan",
  "Asia/Novokuznetsk",
  "Asia/Novosibirsk",
  "Asia/Omsk",
  "Asia/Sakhalin",
  "Asia/Srednekolymsk",
  "Asia/Tomsk",
  "Asia/Ust-Nera",
  "Asia/Vladivostok",
  "Asia/Yakutsk",
  "Asia/Yekaterinburg",
  "Europe/Astrakhan",
  "Europe/Kaliningrad",
  "Europe/Kirov",
  "Europe/Moscow",
  "Europe/Samara",
  "Europe/Saratov",
  "Europe/Simferopol",
  "Europe/Ulyanovsk",
  "Europe/Volgograd",
  // "Europe/Kiev",
  // "Europe/Uzhgorod",
  // "Europe/Zaporozhye",
  // "Europe/Minsk",
  //   "Asia/Almaty",
  // "Asia/Qyzylorda",
  // "Asia/Aqtobe",
  // "Asia/Aqtau",
  // "Asia/Oral",
  // "Asia/Bishkek"
];

async function getTimeZone() {
  const timeZone = Intl.DateTimeFormat()?.resolvedOptions()?.timeZone;
  if (timeZone) {
    return timeZone;
  } else {
    const jstz = await import("jstimezonedetect");
    return jstz.determine().name();
  }
}

export async function hasRussianTimezone() {
  try {
    const timeZone = await getTimeZone();
    return russianTimezones.indexOf(timeZone) != -1;
  } catch (e) {
    return false;
  }
}
