import { setPlaywire, setYandex } from "./ducks/siteData";
import { hasRussianTimezone } from "./util/timezone";

export async function loadAds() {
  const isRussia = await hasRussianTimezone();
  if (isRussia) {
    loadYandex();
  } else {
    loadPlayWire();
  }
}

function loadPlayWire() {
  const head = document.getElementsByTagName("head")[0];

  const aScript = document.createElement("script");
  aScript.type = "text/javascript";
  aScript.async = true;
  aScript.defer = true;
  aScript.src = "//cdn.intergient.com/1024554/73358/ramp.js";
  head.appendChild(aScript);

  window.ramp.onReady = () => {
    setPlaywire();
  };
}

// function loadAdsense() {
//   // if (process.env.NODE_ENV === "development") {
//   //   return;
//   // }

//   const head = document.getElementsByTagName("head")[0];

//   const aScript = document.createElement("script");
//   aScript.type = "text/javascript";
//   aScript.async = true;
//   aScript.src =
//     "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3075327144693930";
//   head.appendChild(aScript);

//   aScript.onload = () => {
//     setAdsense();
//   };
// }

function loadYandex() {
  window.yaContextCb = window.yaContextCb || [];
  const head = document.getElementsByTagName("head")[0];

  const aScript = document.createElement("script");
  aScript.type = "text/javascript";
  aScript.async = true;
  aScript.src = "https://yandex.ru/ads/system/context.js";
  head.appendChild(aScript);

  aScript.onload = () => {
    setYandex();
  };
}
